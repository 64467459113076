import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import android from "../assets/android.svg";
import apple from "../assets/apple.svg";
import QRCode from "react-qr-code";
import { FB_PIXEL_ACCESS_TOKENS, API_URL, DOWNLOAD_URL } from "../lib/urls";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

interface PopupProps {
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const [searchParams] = useSearchParams();

  const adjustId = "1lenvxrs";
  const campaign = searchParams.get("campaign");
  const adgroup = searchParams.get("adgroup");
  const creative = searchParams.get("creative");
  const fbclid = searchParams.get("fbclid");
  const fb_pixel_id = searchParams.get("fb_dynamic_pixel") || "623140467328625";
  const fb_access_token = FB_PIXEL_ACCESS_TOKENS[fb_pixel_id as keyof typeof FB_PIXEL_ACCESS_TOKENS];

  useEffect(() => {
    const getLink = async () => {
      try {
        const url: string = `${window.location.origin}/download`;
        const encodedUrl = encodeURIComponent(DOWNLOAD_URL);
        if (fbclid) {
          const params = {
            campaign,
            adgroup,
            creative,
            fbclid,
            fb_pixel_id,
          };
          const queryString = Object.keys(params)
            .filter(
              (key) =>
                params[key as keyof typeof params] !== null &&
                params[key as keyof typeof params] !== undefined
            )
            .map(
              (key) =>
                `${key}=${encodeURIComponent(
                  params[key as keyof typeof params] as string
                )}`
            )
            .join("&");

          // Modify the URL of the newly opened window
          setDownloadUrl(
            `https://app.adjust.com/${adjustId}?${queryString}&fb_access_token=${fb_access_token}&redirect=${encodedUrl}`
          );
        } else {
          setDownloadUrl(url);
        }
        // const response = await axios.get(API_URL);
        // const url = response.data.link_jump;
        // console.log({ url });
        // setDownloadUrl(url);
      } catch (error) {
        console.error(error);
      }
    };

    getLink();

    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    };
  }, [searchParams]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="rounded-xl shadow-lg relative transform transition-transform duration-300 ease-out scale-95 opacity-100 animate-fade-in cursor-pointer bg-black bg-opacity-70 p-6">
        <button
          className="absolute top-0 right-2 text-white text-lg font-bold transform transition-transform duration-300 ease-out hover:scale-110 text-6xl"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="flex items-center justify-center gap-2 my-1 flex-col">
          <span className="text-lg md:text-xl font-bold font-custom text-white">
            {t("downloadAppNow")}
          </span>
          <span className="text-xs font-bold font-custom text-white opacity-70 mb-1">
            {t("scan")}
          </span>
          {downloadUrl ? (
            <QRCode
              size={150}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`${downloadUrl}`}
              viewBox={`0 0 150 150`}
              className="p-2 bg-white rounded"
            />
          ) : (
            <div role="status" className="my-5">
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          )}
          <div className="flex items-center justify-center gap-2 my-1 opacity-50">
            <img src={android} alt="Big Rummy" className="h-[12px]" />
            <img src={apple} alt="Big Rummy" className="h-[12px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
