export const API_URL = "https://api.djnfcxsk.com/api/mdm/link/64gq4kuj";
export const DOWNLOAD_URL =
  "https://qp-india.s3.ap-south-1.amazonaws.com/apk/test.apk";
export const FB_PIXEL_ACCESS_TOKENS = {
  "929841305146961":
    "EAA3I0OAWmv4BOwg51GRdElTZCe5iGATULeY9SS8xTNPenR2sUCxxVTb5MAxYuDcdvCkcQQH3eXvI7RaJLu54MbTasjZCoNAeITZC0uBCzIqI4B74AfDvnl8O4KYI9NrrOdWjKUq95fZBQJvAomIH16Hi8ivyZAInTQI81aHoAhREiLkn5YfMAt71BiLosZC7FEaAZDZD",
  "1636971393838639":
    "EAAQrsYEAmPcBOwqZCWaaFAern7TyLeDEuS6elcsmhVi8nFjFat04nSajrnVLBQniSIGDgwTZAm9wL59itfY00k33ql0rZCHdsHReb0PGCW5TQsADUBp8AQG0Hsu1sbTuRldNrgulK0IFC3QcHUcRY2CstiTVlXzswNMMUvebUlBTI2iXZBeZApH7dZBWnVic0T9AZDZD",
  "1080716003429065":
    "EAAQrsYEAmPcBO2IpuLy7P1CyDqBUJWx8etxhit0K5ATJZB7XKkxhauQBkqYbzZAvSxD9ZCQy9ZA0P3dz5lbsykM5uuekFpZA6crmRnWTyegTgSOZAEUkeylOxSdg6cyMFUXPt3WJKGpnUkU6brjBZA7ZCVF3ZABnBBMrTtW6K1r0OEf1uZCckNqZBjVfwf8JVdglor6pQZDZD",
  "1799564210786801":
    "EAA3I0OAWmv4BO9hdNPL1m911jo9pqhjufXwXpwNpY2LMgslpUZCGcST4Yal1pZBBOPyvWdKZC3GfBjuglLPL8ds6HTihRXEpqVKb3E3zRjSviBPWW8HtBDp49fBoxRnuDdNZCYB1vAs0j9YFzCxdsiXnS1OywMZCi7RMFUkzoXKJvSED6E6NZAIfgrPQ94nW8DkgZDZD",
  "1075800140650610":
    "EAAOcH08xU6oBOZBnmojAImuc2QazBkObMuaY9eqsIuIkl9aadYNDc4zNqQOdvGTAOZAQyHBC6kFsGv2YNZA2p8NDMk4SaoUggyecQ5E98H14eOeV3CuJKGNFnZCS0ZAarbYAAbZB3MUdCdX04U93ONtpQejVGzA7p9hcj30coMB1TtdWbZAPP23FVuU3nU3ZCKUOsgZDZD",
  "608351928241581":
    "EAA3yDns0uZBsBO7I4HC4t8oDlWAZBCnRYN7I1dY0lZCpZBtD6HEuXfuoZBcesxbsBOLeQ2gj9Jc5ZAZBUvyRGTQ84ADAtU3NDGil4uBoiIUOHBTCYAGGBmxKws3HpJjVPzZCGZChaZAzXiNtbWpWYLDZA8HDCjylyql1rJ1lDS18bdS5LMBdKoIuDJDucEP5tA8s5ZC0mQZDZD",
  "623140467328625":"EAAQXP0mrYdsBO74faIfP2aTTpcjIIEotwBFRIDOmZBA5nUeTrWnsICxlc5a8PWwBglvCazOvJvIz2mz4KKVlZCm6kPr9FShblXJn5XQDP8GCom2eg5T9sHNTKgK3z2JKUV9Aj5iBthzvy1ymAYaDLn9d8S9BxWiwkNHKSQEah6yiLMvPl9mFIaGUK6PgGzxgZDZD"
};
